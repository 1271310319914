var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getChapters)?_c('div',{staticClass:"slides"},[_c('div',{ref:"slideContainer",staticClass:"slide__container"},[_vm._l((_vm.getChapters),function(chapter,chapterIndex){return _vm._l((chapter.slides),function(slide,slideIndex){return _c('div',{key:slide.id,staticClass:"slide",class:[
          { active: _vm.getActiveSlide === slideIndex && _vm.getActiveChapter === chapterIndex },
          { beforeActive: (slideIndex < _vm.getActiveSlide && chapterIndex <= _vm.getActiveChapter) || chapterIndex < _vm.getActiveChapter },
          { afterActive: (slideIndex > _vm.getActiveSlide && chapterIndex >= _vm.getActiveChapter) || chapterIndex > _vm.getActiveChapter} ],attrs:{"data-name":chapterIndex + '-' + slideIndex}},[_c('div',{staticClass:"slide__inner",on:{"click":_vm.handleClickMarker}},[_c('img',{attrs:{"src":slide.imageUrl,"alt":slide.description}}),_vm._l((slide.subslides),function(popup,index){return _c('div',{key:popup.imageUrl,staticClass:"plus",style:({ left: popup.x + '%', top: popup.y + '%' }),on:{"click":function($event){return _vm.handleSubslideToggle(index)}}},[_vm._v(" + ")])})],2)])})}),_c('div',{staticClass:"endslide",class:[
        {
          active:
            _vm.getActiveChapter == _vm.getChapters.length - 1 &&
            _vm.activeSlide === _vm.getChapters[_vm.getActiveChapter].slides.length,
        },
        { afterActive: _vm.getActiveChapter < _vm.getChapters.length - 1 },
        {
          afterActive:
            _vm.getActiveChapter === _vm.getChapters.length - 1 &&
            _vm.activeSlide < _vm.getChapters[_vm.getActiveChapter].slides.length,
        } ]},[_c('PresentationEndScreen')],1)],2),_c('transition',{attrs:{"name":"fadein"}},[(typeof _vm.getPresentationData.openSubslide == 'number')?_c('div',{attrs:{"id":"subslide__container"}},[_c('div',{attrs:{"id":"subslide__close"},on:{"click":_vm.handleSubslideClose}},[_vm._v("✕")]),_c('img',{attrs:{"src":_vm.getSlides[_vm.getActiveSlide].subslides[
            _vm.getPresentationData.openSubslide
          ].imageUrl,"alt":""}})]):_vm._e()]),_c('div',{staticClass:"nav__wrapper"},[_c('div',{staticClass:"navbuttons"},[_c('div',{staticClass:"btn btn__prev",on:{"click":_vm.firstSlideAction}},[_vm._v("⇤")]),_c('div',{staticClass:"btn btn__prev",on:{"click":_vm.previousSlideAction}},[_vm._v("←")]),_c('div',{staticClass:"currentslide"},[_vm._v("Kapitel: "),_c('strong',[_vm._v(_vm._s(_vm.getActiveChapter + 1))]),_vm._v(" - Slide: "),_c('strong',[_vm._v(_vm._s(_vm._f("leadingZero")(_vm.getActiveSlide + 1)))])]),_c('div',{staticClass:"btn btn__next",on:{"click":_vm.nextSlideAction}},[_vm._v("→")])]),_c('div',{staticClass:"btn",class:{ active : _vm.presentationStarted},on:{"click":_vm.togglePresentationStarted}},[_vm._v(" "+_vm._s(_vm.presentationStarted ? "Stoppa presentation" : "Starta presentation")+" ")]),_c('div',{staticClass:"btn",class:{ active : _vm.browsingActive},on:{"click":_vm.toggleBrowsingActive}},[_vm._v(" "+_vm._s(_vm.browsingActive ? "Stoppa fri surf" : "Starta fri surf")+" ")]),_c('div',{staticClass:"btn",class:{ active : _vm.endScreenActive},on:{"click":_vm.toggleEndScreen}},[_vm._v(" "+_vm._s(_vm.endScreenActive ? "Dölj kontaktsidan" : "Visa kontaktsidan")+" ")]),_c('div',{staticClass:"btn",class:{ active : _vm.indexActive},on:{"click":_vm.toggleIndex}},[_vm._v(" Index ")]),_c('div',{staticClass:"btn",class:{ active : _vm.timerActive},on:{"click":_vm.toggleTimer}},[_vm._v(" Stäng om 30 min ")])]),(_vm.indexActive)?_c('div',{attrs:{"id":"indexoverlay"}},[_c('IndexPopup',{on:{"closeIndexPopup":_vm.toggleIndex}}),_c('div',{attrs:{"id":"closeindex"},on:{"click":function($event){_vm.indexActive = false}}},[_vm._v("✕")])],1):_vm._e()],1):_c('div',[_c('h1',[_vm._v("Loading...")])])}
var staticRenderFns = []

export { render, staticRenderFns }