<template>
  <div v-if="getChapters" class="slides">
    <div
      class="slide__container"
      ref="slideContainer"
    >
      <template v-for="(chapter, chapterIndex) in getChapters">
        <div
          class="slide"
          v-for="(slide, slideIndex) in chapter.slides"
          :key="slide.id"
          v-bind:class="[
            { active: getActiveSlide === slideIndex && getActiveChapter === chapterIndex },
            { beforeActive: (slideIndex < getActiveSlide && chapterIndex <= getActiveChapter) || chapterIndex < getActiveChapter },
            { afterActive: (slideIndex > getActiveSlide && chapterIndex >= getActiveChapter) || chapterIndex > getActiveChapter},
          ]"
          :data-name="chapterIndex + '-' + slideIndex"
        >
          <div class="slide__inner" @click="handleClickMarker">
            <img :src="slide.imageUrl" :alt="slide.description" />
            <div
              v-for="(popup, index) in slide.subslides"
              :key="popup.imageUrl"
              :style="{ left: popup.x + '%', top: popup.y + '%' }"
              @click="handleSubslideToggle(index)"
              class="plus"
            >
              +
            </div>
          </div>
        </div>
      </template>
      <div
        class="endslide"
        v-bind:class="[
          {
            active:
              getActiveChapter == getChapters.length - 1 &&
              activeSlide === getChapters[getActiveChapter].slides.length,
          },
          { afterActive: getActiveChapter < getChapters.length - 1 },
          {
            afterActive:
              getActiveChapter === getChapters.length - 1 &&
              activeSlide < getChapters[getActiveChapter].slides.length,
          },
        ]"
      >
        <PresentationEndScreen />
      </div>
    </div>
    <transition name="fadein">
      <div
        id="subslide__container"
        v-if="typeof getPresentationData.openSubslide == 'number'"
      >
        <div id="subslide__close" @click="handleSubslideClose">&#10005;</div>
        <img
          :src="
            getSlides[getActiveSlide].subslides[
              getPresentationData.openSubslide
            ].imageUrl
          "
          alt=""
        />
      </div>
    </transition>
    <div class="nav__wrapper">
      <div class="navbuttons">
        <div @click="firstSlideAction" class="btn btn__prev">&larrb;</div>
        <div @click="previousSlideAction" class="btn btn__prev">&larr;</div>
        <div class="currentslide">Kapitel: <strong>{{ getActiveChapter + 1 }}</strong> - Slide: <strong>{{ getActiveSlide + 1 | leadingZero }}</strong></div>
        <div @click="nextSlideAction" class="btn btn__next">&rarr;</div>
      </div>
      <div class="btn" @click="togglePresentationStarted" :class="{ active : presentationStarted}">
        {{
          presentationStarted ? "Stoppa presentation" : "Starta presentation"
        }}
      </div>
      <div class="btn" @click="toggleBrowsingActive" :class="{ active : browsingActive}">
        {{ browsingActive ? "Stoppa fri surf" : "Starta fri surf" }}
      </div>
      <div class="btn" @click="toggleEndScreen" :class="{ active : endScreenActive}">
        {{ endScreenActive ? "Dölj kontaktsidan" : "Visa kontaktsidan" }}
      </div>
      <div class="btn" @click="toggleIndex" :class="{ active : indexActive}">
        Index
      </div>
      <div class="btn" @click="toggleTimer" :class="{ active : timerActive}">
        Stäng om 30 min
      </div>
    </div>
    <div id="indexoverlay" v-if="indexActive">
      <IndexPopup @closeIndexPopup="toggleIndex"/>
      <div id="closeindex" @click="indexActive = false">✕</div>
    </div>
  </div>
  <div v-else><h1>Loading...</h1></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as fb from "../firebase";
import PresentationEndScreen from "../components/PresentationEndScreen.vue";
import IndexPopup from "../components/IndexPopup.vue";
export default {
  data() {
    return {
      indexActive: false,
      timerActive: false
    }
  },
  props: {
    id: {
      required: true,
    },
  },
  components: {
    PresentationEndScreen,
    IndexPopup
  },
  methods: {
    ...mapActions([
      "GETPRESENTATIONACTION",
      "firstSlideAction",
      "nextSlideAction",
      "previousSlideAction",
      "togglePresentationStarted",
      "toggleBrowsingActive",
      "toggleEndScreen",
    ]),
    handleSubslideToggle(subslideIndex) {
      let id = this.getPresentationData.id;
      if (this.getPresentationData.openSubslide != null) {
        fb.db.collection("presentations").doc(id).update({
          openSubslide: null,
        });
      } else {
        fb.db.collection("presentations").doc(id).update({
          openSubslide: subslideIndex,
        });
      }
    },
    handleSubslideClose() {
      let id = this.getPresentationData.id;
      fb.db.collection("presentations").doc(id).update({
        openSubslide: null,
      });
    },
    handleClickMarker(e) {
      const x = Math.round(
        ((e.clientX - e.target.getBoundingClientRect().left) /
          e.target.getBoundingClientRect().width) *
          100
      );
      const y = Math.round(
        ((e.clientY - e.target.getBoundingClientRect().top) /
          e.target.getBoundingClientRect().height) *
          100
      );
      let id = this.getPresentationData.id;
      fb.db.collection("presentations").doc(id).update({
        "clickMarker.X": x,
        "clickMarker.Y": y,
      });
    },
    toggleIndex() {
      this.indexActive = !this.indexActive
    },
    toggleTimer() {
      this.timerActive = !this.timerActive
    }
  },
  computed: {
    ...mapGetters([
      "getChapters",
      "getPresentationData",
      "getActiveSlide",
      "getActiveChapter",
    ]),
    subslides: function () {
      let _subslides =
        this.getChapters[this.getActiveChapter].slides[this.getActiveSlide]
          .subslides;
      return _subslides;
    },
    presentationStarted: function () {
      return this.getPresentationData.presentationStarted;
    },
    browsingActive: function () {
      return this.getPresentationData.browsingActive;
    },
    endScreenActive: function () {
      return this.getPresentationData.endScreenActive;
    }
  },
  filters: {
    leadingZero (val) {
      return val < 10 ? '0' + val : val;
    }
  },
  beforeMount() {
    this.GETPRESENTATIONACTION(this.id);
  },
};
</script>

<style lang="scss">
.navbuttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.currentslide {
  padding: 0.5rem;
  margin: 0.5rem;
  width: 12rem;
}
.slide__container {
  position: relative;
  width: 100%;
  overflow: hidden;
  flex: 1 1 auto;
}
.slides {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navheight));
}
.slide {
  transition: all 300ms ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide__inner {
  position: relative;
}
.slide__inner img {
  width: auto;
  height: auto;
  max-width: 100vw;
  max-height: calc(100vh - var(--navheight) - var(--slidenavheight));
}
.beforeActive {
  transform: translateX(-100%);
}
.afterActive {
  transform: translateX(100%);
}
.plus {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 24px;
  width: 30px;
  height: 30px;
  background: #ffffff33;
  color: orange;
  border: 2px solid orange;
  border-radius: 30px;
  transition: background 300ms ease-in-out;
  cursor: pointer;
  transform-origin: center center;
  transform: translate3d(-15px, -15px, 0);
  font-weight: 900;
  &:hover {
    background: #ffffffff;
  }
  &:hover::before {
    animation: none;
  }
  &::before {
    z-index: -10;
    display: block;
    position: absolute;
    border: 2px solid orange;
    content: "";
    border-radius: 50px;
    animation: pulse 3s infinite;
    width: 100%;
    height: 100%;
  }
}
#subslide__container {
  padding: 3%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}
#subslide__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.nav__wrapper {
  height: var(--slidenavheight);
}
#closeindex {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  color: white;
  background-color: #4b4b4b;
  font-weight: 700;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: #333;
  }
}
@keyframes pulse {
  0% {
    background: #ffffff00;
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  50% {
    background: #ffffff66;
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  80% {
    background: #ffffffff;
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5);
  }
  100% {
    background: #ffffff00;
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
}
.fadein-enter-active,
.fadein-leave-active {
  transition: opacity 0.5s;
}
.fadein-enter,
.fadein-leave-to {
  opacity: 0;
}
</style>