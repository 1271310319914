<template>
  <div class="index__container">
    <div class="index__inner">
      <div class="left">
        <h2>Index</h2>
        <ol>
          <li v-for="(chapter, index) in getChapters" :class="{selected: index ==  selectedChapter}" :key="chapter.id" @click="setSelectedChapter(index)">{{ chapter.chapterTitle }}</li>
        </ol>
      </div>
      <div class="right">
        <div
          v-for="(slide, index) in getChapters[selectedChapter].slides"
          :key="slide.id"
          class="index__thumbnail"
          @click="gotoSlide(index)"
        >
          <img :src="slide.imageUrl" alt="" />
          <span class="thumbnail__subtitle">{{ slide.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as fb from "../firebase";
export default {
  data() {
    return {
      selectedChapter: 0
    }
  },
  computed: {
    ...mapGetters([
      "getChapters",
      "getPresentationData",
      "getActiveSlide",
      "getActiveChapter",
    ])
  },
  methods: {
    ...mapActions([
      "goToSlideAction"
    ]),
    gotoSlide (e) {
      let id = this.getPresentationData.id;
      fb.db
          .collection("presentations")
          .doc(id)
          .update({
            activeChapter: this.selectedChapter,
            activeSlide: e
          });
      this.$emit('closeIndexPopup')
    },
    setSelectedChapter (e) {
      this.selectedChapter = e
    }
  }
}
</script>

<style lang="scss">
#indexoverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  min-height: 100vh;
  width: 100%;
}
.index__container {
  height: calc(100vh - var(--navbarheight) - var(--footerheight));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  ol li {
    cursor: pointer;
    border-bottom: 2px solid rgba(0,0,0,0);
    transition: border 300ms ease-in-out;
    padding: 2px 0;
    margin-bottom: 5px;
    &.selected {
      border-bottom: 2px solid #333;
    }
  }
}
.index__inner {
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  .left {
    flex: 1 0 auto;
    max-width: 50%;
    margin-right: 3rem;
  }
  .right {
    max-height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .index__thumbnail {
    cursor: pointer;
    flex: 0 1 240px;
    margin: 1rem;
    img {
      width: 100%;
    }
  }
}
</style>
