<template>
  <div class="endscreen__container">
    <h1>Tack för att ni lyssnat!</h1>
    <div class="split__container">
      <div class="left">
        <img
          :src="presenter.avatar"
          :alt="presenter.firstName + ' ' + presenter.lastName"
          class="presenter__image"
        />
        <h2>{{ presenter.firstName }} {{ presenter.lastName }}</h2>
        <h3>{{ presenter.title }}</h3>
        <div class="separator"></div>
        <p><strong>Mobil/SMS:</strong> {{ presenter.phone }}</p>
        <p>
          <strong>Mail:</strong>
          <a :href="'mailto:' + presenter.eMail">{{ presenter.eMail }}</a>
        </p>
      </div>
      <div class="right">
        <p><strong>För att få mer info digitalt, scanna QR-koden</strong></p>
        <canvas id="qrcode"></canvas>
        <p>
          <strong
            >För mer information om TAF/MEK, besök
            <a href="http://www.medhub.se">www.medhub.se</a></strong
          >
        </p>
        <div class="buttoncontainer">
          <div class="btn">
            <span class="btn__text"
              ><svg
                aria-hidden="true"
                focusable="false"
                class="icon"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288z"
                ></path>
              </svg>
              Lämna feedback</span
            >
          </div>
          <div class="btn">
            <span class="btn__text"
              ><svg
                aria-hidden="true"
                focusable="false"
                class="icon"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                ></path></svg
              >Boka nytt möte</span
            >
          </div>
          <div class="btn"  @click="handlePlay(0)">
            <span class="btn__text"
              ><svg
                aria-hidden="true"
                focusable="false"
                class="icon"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                ></path></svg
              >Visa presentationen</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  name: "PresentationEndScreen",
  data: function() {
    return {
      qrContent: "QR-kodens innehåll",
    };
  },
  computed: {
    presenter() {
      return this.$store.state.presenter;
    },
  },
  mounted() {
    var canvas = document.getElementById("qrcode");
    var qrOptions = {
      margin: 0,
      color: {
        dark: "#222222FF",
        light: "#FFFFFFFF",
      },
      width: 200,
      height: 200,
    };
    QRCode.toCanvas(canvas, this.qrContent, qrOptions, function(error) {
      if (error) console.error(error);
      console.log("success!");
    });
  },
  methods: {
    handlePlay(e) {
      this.$store.dispatch('GOTOSLIDE', e)
    }
  }
};
</script>

<style lang="scss">
.endscreen__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  h1 {
    border-bottom: 2px solid var(--black);
    margin-top: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin: 0;
  }
  p {
    margin: 0 0 1rem 0;
  }

  .separator {
    display: block;
    height: 2px;
    background-color: var(--black);
    width: 100%;
    margin: 0.5rem 0;
  }
  img {
    width: 100%;
    display: block;
  }

  .split__container {
    display: flex;
    & > div {
      flex: 0 1 20rem;
      text-align: left;
      margin: 1rem;
    }
  }
  .presenter__image {
    max-width: 240px;
  }
  .btn {
    margin-bottom: 1rem;
  }
  .buttoncontainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
#qrcode {
  margin-bottom: 1rem;
}
svg.icon {
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
