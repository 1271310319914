import { render, staticRenderFns } from "./IndexPopup.vue?vue&type=template&id=49d1f7eb&"
import script from "./IndexPopup.vue?vue&type=script&lang=js&"
export * from "./IndexPopup.vue?vue&type=script&lang=js&"
import style0 from "./IndexPopup.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports